import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client/core';
import { ApolloClients } from '@vue/apollo-composable';
import { boot } from 'quasar/wrappers';
import aws from '../aws-exports';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { Auth } from './amplify';

const awsClientProduction = new AWSAppSyncClient(
  {
    url: aws.productionManagementAppsyncEndpoint,
    region: aws.region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
    offlineConfig: {
      keyPrefix: 'production',
    },
  },
  {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  }
);

const awsClientUserManagement = new AWSAppSyncClient(
  {
    url: aws.userManagementAppsyncEndpoint,
    region: aws.region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
    offlineConfig: {
      keyPrefix: 'userManagement',
    },
  },
  {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  }
);
const cache = new InMemoryCache();
const apolloClientProduction = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  link: awsClientProduction.link as never,
  connectToDevTools: process.env.ENVIRONMENT !== 'prod',
});

const apolloClientUserManagement = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  link: awsClientUserManagement.link as never,
  connectToDevTools: process.env.ENVIRONMENT !== 'prod',
});

export default boot(({ app }) => {
  const apolloClients: Record<string, ApolloClient<NormalizedCacheObject>> = {
    default: apolloClientProduction,
    production: apolloClientProduction,
    userManagement: apolloClientUserManagement,
    // clientA,
    // clientB,
  };

  app.provide(ApolloClients, apolloClients);
});

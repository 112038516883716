/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
  BigInt: any;
  Double: any;
};

export type Batch = {
  __typename?: 'Batch';
  active?: Maybe<Scalars['Int']>;
  batchId: Scalars['String'];
  batchType?: Maybe<Scalars['String']>;
  colli?: Maybe<Array<Collo>>;
  counter?: Maybe<BatchCounter>;
  createdAt: Scalars['AWSDateTime'];
  paint: Scalars['Boolean'];
  sequence: Scalars['Int'];
  startedAt?: Maybe<Scalars['AWSDateTime']>;
  state: BatchState;
  tasks?: Maybe<Array<Task>>;
  updatedAt: Scalars['AWSDateTime'];
};

export type BatchChangedInput = {
  active?: Maybe<Scalars['Int']>;
  batchId: Scalars['String'];
  batchType?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  paint: Scalars['Boolean'];
  sequence: Scalars['Int'];
  startedAt?: Maybe<Scalars['AWSDateTime']>;
  state: BatchState;
  updatedAt: Scalars['AWSDateTime'];
};

export type BatchCounter = {
  __typename?: 'BatchCounter';
  batchId: Scalars['String'];
  colli?: Maybe<BatchCounterStates>;
  createdAt: Scalars['AWSDateTime'];
  station1?: Maybe<BatchCounterStates>;
  station2?: Maybe<BatchCounterStates>;
  station3?: Maybe<BatchCounterStates>;
  station4?: Maybe<BatchCounterStates>;
  station5?: Maybe<BatchCounterStates>;
  station6?: Maybe<BatchCounterStates>;
  station7?: Maybe<BatchCounterStates>;
  station8?: Maybe<BatchCounterStates>;
  station9?: Maybe<BatchCounterStates>;
  station10?: Maybe<BatchCounterStates>;
  updatedAt: Scalars['AWSDateTime'];
};

export type BatchCounterChangedInput = {
  batchId: Scalars['String'];
  colli?: Maybe<BatchCounterStatesInput>;
  createdAt: Scalars['AWSDateTime'];
  station1?: Maybe<BatchCounterStatesInput>;
  station2?: Maybe<BatchCounterStatesInput>;
  station3?: Maybe<BatchCounterStatesInput>;
  station4?: Maybe<BatchCounterStatesInput>;
  station5?: Maybe<BatchCounterStatesInput>;
  station6?: Maybe<BatchCounterStatesInput>;
  station7?: Maybe<BatchCounterStatesInput>;
  station8?: Maybe<BatchCounterStatesInput>;
  station9?: Maybe<BatchCounterStatesInput>;
  station10?: Maybe<BatchCounterStatesInput>;
  updatedAt: Scalars['AWSDateTime'];
};

export enum BatchState {
  Available = 'AVAILABLE',
  Completed = 'COMPLETED',
  Completing = 'COMPLETING',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED'
}

export type BatchStation = {
  __typename?: 'BatchStation';
  active?: Maybe<Scalars['Int']>;
  batch: Batch;
  batchId: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  state: BatchState;
  stationId: Scalars['Int'];
  updatedAt: Scalars['AWSDateTime'];
};

export type BatchStations = {
  __typename?: 'BatchStations';
  items: Array<Maybe<BatchStation>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Batches = {
  __typename?: 'Batches';
  items: Array<Batch>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CognitoGroup = {
  __typename?: 'CognitoGroup';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  groupName: Scalars['String'];
  precedence: Scalars['Int'];
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type Collo = {
  __typename?: 'Collo';
  batchId?: Maybe<Scalars['String']>;
  colloId: Scalars['Int'];
  packingType: Scalars['String'];
  parts: Array<Maybe<Part>>;
  printCodes?: Maybe<Array<Maybe<PrintCode>>>;
  productionWeek?: Maybe<Scalars['Int']>;
  salesOrder: Scalars['String'];
  transportLength: Scalars['Int'];
};

export type ColloInfo = {
  __typename?: 'ColloInfo';
  createdAt: Scalars['AWSDateTime'];
  queuedAt?: Maybe<Scalars['AWSDateTime']>;
  status: ColloState;
  version: Scalars['Int'];
};

export type ColloInfoInput = {
  createdAt: Scalars['AWSDateTime'];
  queuedAt?: Maybe<Scalars['AWSDateTime']>;
  status: ColloState;
  version: Scalars['Int'];
};

export type ColloInput = {
  batchId?: Maybe<Scalars['String']>;
  colloId: Scalars['Int'];
  prio: Scalars['Int'];
  seq: Scalars['Int'];
};

export enum ColloState {
  Available = 'AVAILABLE',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW'
}

export type CompleteTasksInput = {
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
};

export type Config = {
  __typename?: 'Config';
  data: Array<Maybe<ConfigSequence>>;
  info: ConfigInfo;
};

export type ConfigInfo = {
  __typename?: 'ConfigInfo';
  createdAt: Scalars['AWSDateTime'];
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  version: Scalars['Int'];
};

export type ConfigSequence = {
  __typename?: 'ConfigSequence';
  sequence: Scalars['Int'];
  station: Station;
  stationId: Scalars['Int'];
};

export type CreateUserInput = {
  email?: Maybe<Scalars['String']>;
  groups: Array<UserGroup>;
  location: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export enum DataType {
  Batch = 'BATCH',
  BatchStation = 'BATCH_STATION',
  Collo = 'COLLO',
  Task = 'TASK'
}

export type DataTypeFilterInput = {
  eq?: Maybe<DataType>;
};

export type IntFilterInput = {
  eq?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  batchChanged: Batch;
  batchCounterChanged?: Maybe<BatchCounter>;
  changeUserPassword?: Maybe<User>;
  claimBatch?: Maybe<Batch>;
  claimTask?: Maybe<Task>;
  completeTask?: Maybe<Task>;
  completeTasks?: Maybe<Tasks>;
  createUser?: Maybe<User>;
  deleteUser?: Maybe<User>;
  disableUser?: Maybe<User>;
  enableUser?: Maybe<User>;
  moveStopper?: Maybe<Scalars['Boolean']>;
  resetTask?: Maybe<Task>;
  skipTask?: Maybe<Task>;
  stationCounterChanged?: Maybe<StationCounter>;
  stopperPositionChanged?: Maybe<StopperPosition>;
  stopperPositionError?: Maybe<StopperPositionError>;
  taskChanged: Task;
  updateUser?: Maybe<User>;
};


export type MutationBatchChangedArgs = {
  input: BatchChangedInput;
};


export type MutationBatchCounterChangedArgs = {
  input: BatchCounterChangedInput;
};


export type MutationChangeUserPasswordArgs = {
  tempPassword: Scalars['String'];
  username: Scalars['String'];
};


export type MutationClaimBatchArgs = {
  batchId?: Maybe<Scalars['ID']>;
  stationId: Scalars['Int'];
};


export type MutationClaimTaskArgs = {
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
  taskId?: Maybe<Scalars['ID']>;
};


export type MutationCompleteTaskArgs = {
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
  taskId: Scalars['ID'];
};


export type MutationCompleteTasksArgs = {
  input: CompleteTasksInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteUserArgs = {
  username: Scalars['String'];
};


export type MutationDisableUserArgs = {
  username: Scalars['String'];
};


export type MutationEnableUserArgs = {
  username: Scalars['String'];
};


export type MutationMoveStopperArgs = {
  length: Scalars['Int'];
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
};


export type MutationResetTaskArgs = {
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
  taskId: Scalars['ID'];
};


export type MutationSkipTaskArgs = {
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
  taskId: Scalars['ID'];
};


export type MutationStationCounterChangedArgs = {
  input: StationCounterChangedInput;
};


export type MutationStopperPositionChangedArgs = {
  input: StopperPositionChangedInput;
};


export type MutationStopperPositionErrorArgs = {
  input: StopperPositionErrorInput;
};


export type MutationTaskChangedArgs = {
  input: TaskChangedInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type PaginatedCognitoGroups = {
  __typename?: 'PaginatedCognitoGroups';
  items: Array<CognitoGroup>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Part = {
  __typename?: 'Part';
  colorInside?: Maybe<Scalars['String']>;
  colorOutside?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  endcapTopBottom?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  painterId?: Maybe<Scalars['String']>;
  partNo: Scalars['String'];
  pickLocation?: Maybe<Scalars['String']>;
  qty: Scalars['Int'];
  specialColorId?: Maybe<Scalars['String']>;
  stopperPosition?: Maybe<Scalars['Int']>;
};

export type PrintCode = {
  __typename?: 'PrintCode';
  department: Scalars['String'];
  qrCode: Scalars['String'];
};

export type Printer = {
  __typename?: 'Printer';
  SubStationId: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  ipAddress: Scalars['String'];
  name: Scalars['String'];
  printerId: Scalars['Int'];
  stationId: Scalars['Int'];
  updatedAt: Scalars['AWSDateTime'];
  zpl: Scalars['String'];
};

export type ProductionQueue = {
  __typename?: 'ProductionQueue';
  colloId: Scalars['Int'];
  createdAt: Scalars['AWSDateTime'];
  data: Collo;
  info?: Maybe<ColloInfo>;
  ppStationId1?: Maybe<Scalars['String']>;
  ppStationId2?: Maybe<Scalars['String']>;
  ppStationId3?: Maybe<Scalars['String']>;
  ppStationId4?: Maybe<Scalars['String']>;
  ppStationId5?: Maybe<Scalars['String']>;
  ppStationId6?: Maybe<Scalars['String']>;
  ppStationId7?: Maybe<Scalars['String']>;
  prio: Scalars['Int'];
  queued?: Maybe<Scalars['Int']>;
  queuedAt: Scalars['AWSDateTime'];
  seq: Scalars['Int'];
  tasksAvailable: Scalars['Int'];
  tasksCompleted: Scalars['Int'];
  updatedAt: Scalars['AWSDateTime'];
};

export type ProductionQueues = {
  __typename?: 'ProductionQueues';
  items: Array<ProductionQueue>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getActiveBatchStationsForStation: BatchStations;
  getActiveBatchStationsWithTaskForStation: BatchStations;
  getBatches: Batches;
  getConfig: Config;
  getQueuedBatchStationsForStation: BatchStations;
  getQueuedBatchStationsWithTaskForStation: BatchStations;
  getQueuedBatches: Batches;
  getQueuedItems: QueuedItems;
  getStation: Station;
  getStationCounters: StationCounters;
  getStations: Stations;
  getTasksByBatchId: Tasks;
  groups: PaginatedCognitoGroups;
  users: Users;
};


export type QueryGetActiveBatchStationsForStationArgs = {
  nextToken?: Maybe<Scalars['String']>;
  stationId: Scalars['Int'];
};


export type QueryGetActiveBatchStationsWithTaskForStationArgs = {
  nextToken?: Maybe<Scalars['String']>;
  stationId: Scalars['Int'];
};


export type QueryGetBatchesArgs = {
  stationId?: Maybe<Scalars['Int']>;
};


export type QueryGetConfigArgs = {
  configOrderId: Scalars['Int'];
};


export type QueryGetQueuedBatchStationsForStationArgs = {
  nextToken?: Maybe<Scalars['String']>;
  stationId: Scalars['Int'];
};


export type QueryGetQueuedBatchStationsWithTaskForStationArgs = {
  nextToken?: Maybe<Scalars['String']>;
  stationId: Scalars['Int'];
};


export type QueryGetQueuedBatchesArgs = {
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetQueuedItemsArgs = {
  filter?: Maybe<QueuedItemFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  stationId?: Maybe<Scalars['Int']>;
};


export type QueryGetStationArgs = {
  stationId: Scalars['Int'];
};


export type QueryGetStationCountersArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetStationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetTasksByBatchIdArgs = {
  batchId: Scalars['String'];
  filter?: Maybe<TaskFilterInput>;
};


export type QueryUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
  location: Scalars['String'];
  nextToken?: Maybe<Scalars['String']>;
};

export type QueuedItem = {
  __typename?: 'QueuedItem';
  batch?: Maybe<Batch>;
  batchId?: Maybe<Scalars['String']>;
  collo?: Maybe<Collo>;
  colloId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['AWSDateTime'];
  paint?: Maybe<Scalars['Boolean']>;
  queuedAt?: Maybe<Scalars['AWSDateTime']>;
  state: Scalars['String'];
  stationId?: Maybe<Scalars['Int']>;
  subStationId?: Maybe<Scalars['ID']>;
  taskId?: Maybe<Scalars['ID']>;
  type: DataType;
  updatedAt: Scalars['AWSDateTime'];
};

export type QueuedItemFilterInput = {
  type?: Maybe<DataTypeFilterInput>;
};

export type QueuedItems = {
  __typename?: 'QueuedItems';
  items: Array<QueuedItem>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Schema = {
  __typename?: 'Schema';
  mutation?: Maybe<Mutation>;
  query?: Maybe<Query>;
  subscription?: Maybe<Subscription>;
};

export type Station = {
  __typename?: 'Station';
  counter?: Maybe<StationCounter>;
  createdAt: Scalars['AWSDateTime'];
  department?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rootDepartment?: Maybe<Scalars['String']>;
  stationId: Scalars['Int'];
  subStations?: Maybe<Array<Maybe<SubStation>>>;
  updatedAt: Scalars['AWSDateTime'];
};

export type StationCounter = {
  __typename?: 'StationCounter';
  available?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Int']>;
  createdAt: Scalars['AWSDateTime'];
  inProgress?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Int']>;
  skipped?: Maybe<Scalars['Int']>;
  stationId: Scalars['Int'];
  updatedAt: Scalars['AWSDateTime'];
};

export type StationCounterChangedInput = {
  available?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Int']>;
  createdAt: Scalars['AWSDateTime'];
  inProgress?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Int']>;
  skipped?: Maybe<Scalars['Int']>;
  stationId: Scalars['Int'];
  updatedAt: Scalars['AWSDateTime'];
};

export type StationCounters = {
  __typename?: 'StationCounters';
  items: Array<StationCounter>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Stations = {
  __typename?: 'Stations';
  items: Array<Maybe<Station>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type StopperPosition = {
  __typename?: 'StopperPosition';
  length: Scalars['Int'];
  state: StopperState;
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
};

export type StopperPositionChangedInput = {
  length: Scalars['Int'];
  state: StopperState;
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
};

export type StopperPositionError = {
  __typename?: 'StopperPositionError';
  code: Scalars['Int'];
  message: Scalars['String'];
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
};

export type StopperPositionErrorInput = {
  code: Scalars['Int'];
  message: Scalars['String'];
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
};

export enum StopperState {
  OnPosition = 'ON_POSITION'
}

export type StringFilterInput = {
  beginsWith?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
};

export type SubStation = {
  __typename?: 'SubStation';
  config: SubStationConfig;
  createdAt: Scalars['AWSDateTime'];
  name?: Maybe<Scalars['String']>;
  printers?: Maybe<Array<Maybe<Printer>>>;
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
};

export type SubStationConfig = {
  __typename?: 'SubStationConfig';
  lengthStopper: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onBatchChanged?: Maybe<Batch>;
  onBatchCounterChanged?: Maybe<BatchCounter>;
  onStationCounterChanged?: Maybe<StationCounter>;
  onStopperPositionChanged?: Maybe<StopperPosition>;
  onStopperPositionError?: Maybe<StopperPositionError>;
  onTaskChanged?: Maybe<Task>;
  onUserChanged?: Maybe<User>;
};


export type SubscriptionOnBatchChangedArgs = {
  batchId?: Maybe<Scalars['ID']>;
};


export type SubscriptionOnBatchCounterChangedArgs = {
  batchId?: Maybe<Scalars['ID']>;
};


export type SubscriptionOnStationCounterChangedArgs = {
  stationId?: Maybe<Scalars['Int']>;
};


export type SubscriptionOnStopperPositionChangedArgs = {
  stationId?: Maybe<Scalars['Int']>;
  subStationId?: Maybe<Scalars['ID']>;
};


export type SubscriptionOnStopperPositionErrorArgs = {
  stationId?: Maybe<Scalars['Int']>;
  subStationId?: Maybe<Scalars['ID']>;
};


export type SubscriptionOnTaskChangedArgs = {
  taskId?: Maybe<Scalars['ID']>;
};


export type SubscriptionOnUserChangedArgs = {
  username?: Maybe<Scalars['String']>;
};

export type Task = {
  __typename?: 'Task';
  batch?: Maybe<Batch>;
  batchId: Scalars['String'];
  claimedAt?: Maybe<Scalars['AWSDateTime']>;
  collo?: Maybe<Collo>;
  colloId: Scalars['Int'];
  completedAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt: Scalars['AWSDateTime'];
  paint: Scalars['Boolean'];
  queued?: Maybe<Scalars['Int']>;
  queuedAt?: Maybe<Scalars['AWSDateTime']>;
  rootDepartment?: Maybe<Scalars['String']>;
  state: TaskState;
  station?: Maybe<Station>;
  stationId: Scalars['Int'];
  sub?: Maybe<Scalars['String']>;
  subStationId?: Maybe<Scalars['ID']>;
  taskId: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  user?: Maybe<User>;
};

export type TaskChangedInput = {
  batchId: Scalars['String'];
  colloId: Scalars['Int'];
  createdAt: Scalars['AWSDateTime'];
  paint: Scalars['Boolean'];
  queued?: Maybe<Scalars['Int']>;
  queuedAt?: Maybe<Scalars['AWSDateTime']>;
  state: TaskState;
  stationId: Scalars['Int'];
  subStationId?: Maybe<Scalars['ID']>;
  taskId: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
};

export type TaskFilterInput = {
  state?: Maybe<StringFilterInput>;
  stationId?: Maybe<IntFilterInput>;
};

export enum TaskState {
  Available = 'AVAILABLE',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Skipped = 'SKIPPED'
}

export type Tasks = {
  __typename?: 'Tasks';
  items: Array<Task>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  groups: Array<Maybe<UserGroup>>;
  username: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  groups: Array<Maybe<UserGroup>>;
  location: Scalars['String'];
  userId: Scalars['String'];
  username: Scalars['String'];
};

export enum UserGroup {
  AdminUsers = 'AdminUsers',
  Dashboard = 'Dashboard',
  SuperUsers = 'SuperUsers',
  Users = 'Users'
}

export type Users = {
  __typename?: 'Users';
  items?: Maybe<Array<Maybe<User>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type BatchCounterStates = {
  __typename?: 'batchCounterStates';
  available?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Int']>;
  inProgress?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Int']>;
  skipped?: Maybe<Scalars['Int']>;
};

export type BatchCounterStatesInput = {
  available?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Int']>;
  inProgress?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Int']>;
  skipped?: Maybe<Scalars['Int']>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AWSDate: ResolverTypeWrapper<Scalars['AWSDate']>;
  AWSDateTime: ResolverTypeWrapper<Scalars['AWSDateTime']>;
  AWSEmail: ResolverTypeWrapper<Scalars['AWSEmail']>;
  AWSIPAddress: ResolverTypeWrapper<Scalars['AWSIPAddress']>;
  AWSJSON: ResolverTypeWrapper<Scalars['AWSJSON']>;
  AWSPhone: ResolverTypeWrapper<Scalars['AWSPhone']>;
  AWSTime: ResolverTypeWrapper<Scalars['AWSTime']>;
  AWSTimestamp: ResolverTypeWrapper<Scalars['AWSTimestamp']>;
  AWSURL: ResolverTypeWrapper<Scalars['AWSURL']>;
  Batch: ResolverTypeWrapper<Batch>;
  BatchChangedInput: BatchChangedInput;
  BatchCounter: ResolverTypeWrapper<BatchCounter>;
  BatchCounterChangedInput: BatchCounterChangedInput;
  BatchState: BatchState;
  BatchStation: ResolverTypeWrapper<BatchStation>;
  BatchStations: ResolverTypeWrapper<BatchStations>;
  Batches: ResolverTypeWrapper<Batches>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CognitoGroup: ResolverTypeWrapper<CognitoGroup>;
  Collo: ResolverTypeWrapper<Collo>;
  ColloInfo: ResolverTypeWrapper<ColloInfo>;
  ColloInfoInput: ColloInfoInput;
  ColloInput: ColloInput;
  ColloState: ColloState;
  CompleteTasksInput: CompleteTasksInput;
  Config: ResolverTypeWrapper<Config>;
  ConfigInfo: ResolverTypeWrapper<ConfigInfo>;
  ConfigSequence: ResolverTypeWrapper<ConfigSequence>;
  CreateUserInput: CreateUserInput;
  DataType: DataType;
  DataTypeFilterInput: DataTypeFilterInput;
  Double: ResolverTypeWrapper<Scalars['Double']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  IntFilterInput: IntFilterInput;
  Mutation: ResolverTypeWrapper<{}>;
  PaginatedCognitoGroups: ResolverTypeWrapper<PaginatedCognitoGroups>;
  Part: ResolverTypeWrapper<Part>;
  PrintCode: ResolverTypeWrapper<PrintCode>;
  Printer: ResolverTypeWrapper<Printer>;
  ProductionQueue: ResolverTypeWrapper<ProductionQueue>;
  ProductionQueues: ResolverTypeWrapper<ProductionQueues>;
  Query: ResolverTypeWrapper<{}>;
  QueuedItem: ResolverTypeWrapper<QueuedItem>;
  QueuedItemFilterInput: QueuedItemFilterInput;
  QueuedItems: ResolverTypeWrapper<QueuedItems>;
  Schema: ResolverTypeWrapper<Schema>;
  Station: ResolverTypeWrapper<Station>;
  StationCounter: ResolverTypeWrapper<StationCounter>;
  StationCounterChangedInput: StationCounterChangedInput;
  StationCounters: ResolverTypeWrapper<StationCounters>;
  Stations: ResolverTypeWrapper<Stations>;
  StopperPosition: ResolverTypeWrapper<StopperPosition>;
  StopperPositionChangedInput: StopperPositionChangedInput;
  StopperPositionError: ResolverTypeWrapper<StopperPositionError>;
  StopperPositionErrorInput: StopperPositionErrorInput;
  StopperState: StopperState;
  String: ResolverTypeWrapper<Scalars['String']>;
  StringFilterInput: StringFilterInput;
  SubStation: ResolverTypeWrapper<SubStation>;
  SubStationConfig: ResolverTypeWrapper<SubStationConfig>;
  Subscription: ResolverTypeWrapper<{}>;
  Task: ResolverTypeWrapper<Task>;
  TaskChangedInput: TaskChangedInput;
  TaskFilterInput: TaskFilterInput;
  TaskState: TaskState;
  Tasks: ResolverTypeWrapper<Tasks>;
  UpdateUserInput: UpdateUserInput;
  User: ResolverTypeWrapper<User>;
  UserGroup: UserGroup;
  Users: ResolverTypeWrapper<Users>;
  batchCounterStates: ResolverTypeWrapper<BatchCounterStates>;
  batchCounterStatesInput: BatchCounterStatesInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AWSDate: Scalars['AWSDate'];
  AWSDateTime: Scalars['AWSDateTime'];
  AWSEmail: Scalars['AWSEmail'];
  AWSIPAddress: Scalars['AWSIPAddress'];
  AWSJSON: Scalars['AWSJSON'];
  AWSPhone: Scalars['AWSPhone'];
  AWSTime: Scalars['AWSTime'];
  AWSTimestamp: Scalars['AWSTimestamp'];
  AWSURL: Scalars['AWSURL'];
  Batch: Batch;
  BatchChangedInput: BatchChangedInput;
  BatchCounter: BatchCounter;
  BatchCounterChangedInput: BatchCounterChangedInput;
  BatchStation: BatchStation;
  BatchStations: BatchStations;
  Batches: Batches;
  BigInt: Scalars['BigInt'];
  Boolean: Scalars['Boolean'];
  CognitoGroup: CognitoGroup;
  Collo: Collo;
  ColloInfo: ColloInfo;
  ColloInfoInput: ColloInfoInput;
  ColloInput: ColloInput;
  CompleteTasksInput: CompleteTasksInput;
  Config: Config;
  ConfigInfo: ConfigInfo;
  ConfigSequence: ConfigSequence;
  CreateUserInput: CreateUserInput;
  DataTypeFilterInput: DataTypeFilterInput;
  Double: Scalars['Double'];
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  IntFilterInput: IntFilterInput;
  Mutation: {};
  PaginatedCognitoGroups: PaginatedCognitoGroups;
  Part: Part;
  PrintCode: PrintCode;
  Printer: Printer;
  ProductionQueue: ProductionQueue;
  ProductionQueues: ProductionQueues;
  Query: {};
  QueuedItem: QueuedItem;
  QueuedItemFilterInput: QueuedItemFilterInput;
  QueuedItems: QueuedItems;
  Schema: Schema;
  Station: Station;
  StationCounter: StationCounter;
  StationCounterChangedInput: StationCounterChangedInput;
  StationCounters: StationCounters;
  Stations: Stations;
  StopperPosition: StopperPosition;
  StopperPositionChangedInput: StopperPositionChangedInput;
  StopperPositionError: StopperPositionError;
  StopperPositionErrorInput: StopperPositionErrorInput;
  String: Scalars['String'];
  StringFilterInput: StringFilterInput;
  SubStation: SubStation;
  SubStationConfig: SubStationConfig;
  Subscription: {};
  Task: Task;
  TaskChangedInput: TaskChangedInput;
  TaskFilterInput: TaskFilterInput;
  Tasks: Tasks;
  UpdateUserInput: UpdateUserInput;
  User: User;
  Users: Users;
  batchCounterStates: BatchCounterStates;
  batchCounterStatesInput: BatchCounterStatesInput;
};

export type Aws_Api_KeyDirectiveArgs = { };

export type Aws_Api_KeyDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Api_KeyDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_AuthDirectiveArgs = {
  cognito_groups: Array<Scalars['String']>;
};

export type Aws_AuthDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_AuthDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_Cognito_User_PoolsDirectiveArgs = {
  cognito_groups?: Maybe<Array<Scalars['String']>>;
};

export type Aws_Cognito_User_PoolsDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Cognito_User_PoolsDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_IamDirectiveArgs = { };

export type Aws_IamDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_IamDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_OidcDirectiveArgs = { };

export type Aws_OidcDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_OidcDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_SubscribeDirectiveArgs = {
  mutations: Array<Scalars['String']>;
};

export type Aws_SubscribeDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_SubscribeDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export interface AwsDateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSDate'], any> {
  name: 'AWSDate';
}

export interface AwsDateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSDateTime'], any> {
  name: 'AWSDateTime';
}

export interface AwsEmailScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSEmail'], any> {
  name: 'AWSEmail';
}

export interface AwsipAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSIPAddress'], any> {
  name: 'AWSIPAddress';
}

export interface AwsjsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSJSON'], any> {
  name: 'AWSJSON';
}

export interface AwsPhoneScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSPhone'], any> {
  name: 'AWSPhone';
}

export interface AwsTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSTime'], any> {
  name: 'AWSTime';
}

export interface AwsTimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSTimestamp'], any> {
  name: 'AWSTimestamp';
}

export interface AwsurlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSURL'], any> {
  name: 'AWSURL';
}

export type BatchResolvers<ContextType = any, ParentType extends ResolversParentTypes['Batch'] = ResolversParentTypes['Batch']> = {
  active?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  batchId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  batchType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colli?: Resolver<Maybe<Array<ResolversTypes['Collo']>>, ParentType, ContextType>;
  counter?: Resolver<Maybe<ResolversTypes['BatchCounter']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  paint?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sequence?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['BatchState'], ParentType, ContextType>;
  tasks?: Resolver<Maybe<Array<ResolversTypes['Task']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchCounterResolvers<ContextType = any, ParentType extends ResolversParentTypes['BatchCounter'] = ResolversParentTypes['BatchCounter']> = {
  batchId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  colli?: Resolver<Maybe<ResolversTypes['batchCounterStates']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  station1?: Resolver<Maybe<ResolversTypes['batchCounterStates']>, ParentType, ContextType>;
  station2?: Resolver<Maybe<ResolversTypes['batchCounterStates']>, ParentType, ContextType>;
  station3?: Resolver<Maybe<ResolversTypes['batchCounterStates']>, ParentType, ContextType>;
  station4?: Resolver<Maybe<ResolversTypes['batchCounterStates']>, ParentType, ContextType>;
  station5?: Resolver<Maybe<ResolversTypes['batchCounterStates']>, ParentType, ContextType>;
  station6?: Resolver<Maybe<ResolversTypes['batchCounterStates']>, ParentType, ContextType>;
  station7?: Resolver<Maybe<ResolversTypes['batchCounterStates']>, ParentType, ContextType>;
  station8?: Resolver<Maybe<ResolversTypes['batchCounterStates']>, ParentType, ContextType>;
  station9?: Resolver<Maybe<ResolversTypes['batchCounterStates']>, ParentType, ContextType>;
  station10?: Resolver<Maybe<ResolversTypes['batchCounterStates']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchStationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BatchStation'] = ResolversParentTypes['BatchStation']> = {
  active?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  batch?: Resolver<ResolversTypes['Batch'], ParentType, ContextType>;
  batchId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['BatchState'], ParentType, ContextType>;
  stationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchStationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BatchStations'] = ResolversParentTypes['BatchStations']> = {
  items?: Resolver<Array<Maybe<ResolversTypes['BatchStation']>>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Batches'] = ResolversParentTypes['Batches']> = {
  items?: Resolver<Array<ResolversTypes['Batch']>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type CognitoGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['CognitoGroup'] = ResolversParentTypes['CognitoGroup']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  groupName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  precedence?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColloResolvers<ContextType = any, ParentType extends ResolversParentTypes['Collo'] = ResolversParentTypes['Collo']> = {
  batchId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colloId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  packingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parts?: Resolver<Array<Maybe<ResolversTypes['Part']>>, ParentType, ContextType>;
  printCodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['PrintCode']>>>, ParentType, ContextType>;
  productionWeek?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salesOrder?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transportLength?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColloInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ColloInfo'] = ResolversParentTypes['ColloInfo']> = {
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  queuedAt?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ColloState'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['Config'] = ResolversParentTypes['Config']> = {
  data?: Resolver<Array<Maybe<ResolversTypes['ConfigSequence']>>, ParentType, ContextType>;
  info?: Resolver<ResolversTypes['ConfigInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConfigInfo'] = ResolversParentTypes['ConfigInfo']> = {
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigSequenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConfigSequence'] = ResolversParentTypes['ConfigSequence']> = {
  sequence?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  station?: Resolver<ResolversTypes['Station'], ParentType, ContextType>;
  stationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DoubleScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Double'], any> {
  name: 'Double';
}

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  batchChanged?: Resolver<ResolversTypes['Batch'], ParentType, ContextType, RequireFields<MutationBatchChangedArgs, 'input'>>;
  batchCounterChanged?: Resolver<Maybe<ResolversTypes['BatchCounter']>, ParentType, ContextType, RequireFields<MutationBatchCounterChangedArgs, 'input'>>;
  changeUserPassword?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationChangeUserPasswordArgs, 'tempPassword' | 'username'>>;
  claimBatch?: Resolver<Maybe<ResolversTypes['Batch']>, ParentType, ContextType, RequireFields<MutationClaimBatchArgs, 'stationId'>>;
  claimTask?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<MutationClaimTaskArgs, 'stationId' | 'subStationId'>>;
  completeTask?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<MutationCompleteTaskArgs, 'stationId' | 'subStationId' | 'taskId'>>;
  completeTasks?: Resolver<Maybe<ResolversTypes['Tasks']>, ParentType, ContextType, RequireFields<MutationCompleteTasksArgs, 'input'>>;
  createUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'input'>>;
  deleteUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'username'>>;
  disableUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationDisableUserArgs, 'username'>>;
  enableUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationEnableUserArgs, 'username'>>;
  moveStopper?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationMoveStopperArgs, 'length' | 'stationId' | 'subStationId'>>;
  resetTask?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<MutationResetTaskArgs, 'stationId' | 'subStationId' | 'taskId'>>;
  skipTask?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<MutationSkipTaskArgs, 'stationId' | 'subStationId' | 'taskId'>>;
  stationCounterChanged?: Resolver<Maybe<ResolversTypes['StationCounter']>, ParentType, ContextType, RequireFields<MutationStationCounterChangedArgs, 'input'>>;
  stopperPositionChanged?: Resolver<Maybe<ResolversTypes['StopperPosition']>, ParentType, ContextType, RequireFields<MutationStopperPositionChangedArgs, 'input'>>;
  stopperPositionError?: Resolver<Maybe<ResolversTypes['StopperPositionError']>, ParentType, ContextType, RequireFields<MutationStopperPositionErrorArgs, 'input'>>;
  taskChanged?: Resolver<ResolversTypes['Task'], ParentType, ContextType, RequireFields<MutationTaskChangedArgs, 'input'>>;
  updateUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'input'>>;
};

export type PaginatedCognitoGroupsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedCognitoGroups'] = ResolversParentTypes['PaginatedCognitoGroups']> = {
  items?: Resolver<Array<ResolversTypes['CognitoGroup']>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartResolvers<ContextType = any, ParentType extends ResolversParentTypes['Part'] = ResolversParentTypes['Part']> = {
  colorInside?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colorOutside?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endcapTopBottom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  painterId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partNo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pickLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  qty?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  specialColorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stopperPosition?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrintCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrintCode'] = ResolversParentTypes['PrintCode']> = {
  department?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  qrCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrinterResolvers<ContextType = any, ParentType extends ResolversParentTypes['Printer'] = ResolversParentTypes['Printer']> = {
  SubStationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  ipAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  printerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  zpl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductionQueueResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductionQueue'] = ResolversParentTypes['ProductionQueue']> = {
  colloId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['Collo'], ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['ColloInfo']>, ParentType, ContextType>;
  ppStationId1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ppStationId2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ppStationId3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ppStationId4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ppStationId5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ppStationId6?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ppStationId7?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prio?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  queued?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  queuedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  seq?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tasksAvailable?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tasksCompleted?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductionQueuesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductionQueues'] = ResolversParentTypes['ProductionQueues']> = {
  items?: Resolver<Array<ResolversTypes['ProductionQueue']>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getActiveBatchStationsForStation?: Resolver<ResolversTypes['BatchStations'], ParentType, ContextType, RequireFields<QueryGetActiveBatchStationsForStationArgs, 'stationId'>>;
  getActiveBatchStationsWithTaskForStation?: Resolver<ResolversTypes['BatchStations'], ParentType, ContextType, RequireFields<QueryGetActiveBatchStationsWithTaskForStationArgs, 'stationId'>>;
  getBatches?: Resolver<ResolversTypes['Batches'], ParentType, ContextType, RequireFields<QueryGetBatchesArgs, never>>;
  getConfig?: Resolver<ResolversTypes['Config'], ParentType, ContextType, RequireFields<QueryGetConfigArgs, 'configOrderId'>>;
  getQueuedBatchStationsForStation?: Resolver<ResolversTypes['BatchStations'], ParentType, ContextType, RequireFields<QueryGetQueuedBatchStationsForStationArgs, 'stationId'>>;
  getQueuedBatchStationsWithTaskForStation?: Resolver<ResolversTypes['BatchStations'], ParentType, ContextType, RequireFields<QueryGetQueuedBatchStationsWithTaskForStationArgs, 'stationId'>>;
  getQueuedBatches?: Resolver<ResolversTypes['Batches'], ParentType, ContextType, RequireFields<QueryGetQueuedBatchesArgs, never>>;
  getQueuedItems?: Resolver<ResolversTypes['QueuedItems'], ParentType, ContextType, RequireFields<QueryGetQueuedItemsArgs, never>>;
  getStation?: Resolver<ResolversTypes['Station'], ParentType, ContextType, RequireFields<QueryGetStationArgs, 'stationId'>>;
  getStationCounters?: Resolver<ResolversTypes['StationCounters'], ParentType, ContextType, RequireFields<QueryGetStationCountersArgs, never>>;
  getStations?: Resolver<ResolversTypes['Stations'], ParentType, ContextType, RequireFields<QueryGetStationsArgs, never>>;
  getTasksByBatchId?: Resolver<ResolversTypes['Tasks'], ParentType, ContextType, RequireFields<QueryGetTasksByBatchIdArgs, 'batchId'>>;
  groups?: Resolver<ResolversTypes['PaginatedCognitoGroups'], ParentType, ContextType>;
  users?: Resolver<ResolversTypes['Users'], ParentType, ContextType, RequireFields<QueryUsersArgs, 'location'>>;
};

export type QueuedItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueuedItem'] = ResolversParentTypes['QueuedItem']> = {
  batch?: Resolver<Maybe<ResolversTypes['Batch']>, ParentType, ContextType>;
  batchId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  collo?: Resolver<Maybe<ResolversTypes['Collo']>, ParentType, ContextType>;
  colloId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  paint?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  queuedAt?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subStationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DataType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueuedItemsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueuedItems'] = ResolversParentTypes['QueuedItems']> = {
  items?: Resolver<Array<ResolversTypes['QueuedItem']>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchemaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Schema'] = ResolversParentTypes['Schema']> = {
  mutation?: Resolver<Maybe<ResolversTypes['Mutation']>, ParentType, ContextType>;
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>;
  subscription?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Station'] = ResolversParentTypes['Station']> = {
  counter?: Resolver<Maybe<ResolversTypes['StationCounter']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rootDepartment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subStations?: Resolver<Maybe<Array<Maybe<ResolversTypes['SubStation']>>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StationCounterResolvers<ContextType = any, ParentType extends ResolversParentTypes['StationCounter'] = ResolversParentTypes['StationCounter']> = {
  available?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  inProgress?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  new?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  skipped?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StationCountersResolvers<ContextType = any, ParentType extends ResolversParentTypes['StationCounters'] = ResolversParentTypes['StationCounters']> = {
  items?: Resolver<Array<ResolversTypes['StationCounter']>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Stations'] = ResolversParentTypes['Stations']> = {
  items?: Resolver<Array<Maybe<ResolversTypes['Station']>>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StopperPositionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StopperPosition'] = ResolversParentTypes['StopperPosition']> = {
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['StopperState'], ParentType, ContextType>;
  stationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subStationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StopperPositionErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['StopperPositionError'] = ResolversParentTypes['StopperPositionError']> = {
  code?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subStationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubStationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubStation'] = ResolversParentTypes['SubStation']> = {
  config?: Resolver<ResolversTypes['SubStationConfig'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  printers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Printer']>>>, ParentType, ContextType>;
  stationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subStationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubStationConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubStationConfig'] = ResolversParentTypes['SubStationConfig']> = {
  lengthStopper?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  onBatchChanged?: SubscriptionResolver<Maybe<ResolversTypes['Batch']>, "onBatchChanged", ParentType, ContextType, RequireFields<SubscriptionOnBatchChangedArgs, never>>;
  onBatchCounterChanged?: SubscriptionResolver<Maybe<ResolversTypes['BatchCounter']>, "onBatchCounterChanged", ParentType, ContextType, RequireFields<SubscriptionOnBatchCounterChangedArgs, never>>;
  onStationCounterChanged?: SubscriptionResolver<Maybe<ResolversTypes['StationCounter']>, "onStationCounterChanged", ParentType, ContextType, RequireFields<SubscriptionOnStationCounterChangedArgs, never>>;
  onStopperPositionChanged?: SubscriptionResolver<Maybe<ResolversTypes['StopperPosition']>, "onStopperPositionChanged", ParentType, ContextType, RequireFields<SubscriptionOnStopperPositionChangedArgs, never>>;
  onStopperPositionError?: SubscriptionResolver<Maybe<ResolversTypes['StopperPositionError']>, "onStopperPositionError", ParentType, ContextType, RequireFields<SubscriptionOnStopperPositionErrorArgs, never>>;
  onTaskChanged?: SubscriptionResolver<Maybe<ResolversTypes['Task']>, "onTaskChanged", ParentType, ContextType, RequireFields<SubscriptionOnTaskChangedArgs, never>>;
  onUserChanged?: SubscriptionResolver<Maybe<ResolversTypes['User']>, "onUserChanged", ParentType, ContextType, RequireFields<SubscriptionOnUserChangedArgs, never>>;
};

export type TaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['Task'] = ResolversParentTypes['Task']> = {
  batch?: Resolver<Maybe<ResolversTypes['Batch']>, ParentType, ContextType>;
  batchId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  claimedAt?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  collo?: Resolver<Maybe<ResolversTypes['Collo']>, ParentType, ContextType>;
  colloId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  paint?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  queued?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  queuedAt?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  rootDepartment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['TaskState'], ParentType, ContextType>;
  station?: Resolver<Maybe<ResolversTypes['Station']>, ParentType, ContextType>;
  stationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sub?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subStationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  taskId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tasks'] = ResolversParentTypes['Tasks']> = {
  items?: Resolver<Array<ResolversTypes['Task']>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  groups?: Resolver<Array<Maybe<ResolversTypes['UserGroup']>>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResolvers<ContextType = any, ParentType extends ResolversParentTypes['Users'] = ResolversParentTypes['Users']> = {
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchCounterStatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['batchCounterStates'] = ResolversParentTypes['batchCounterStates']> = {
  available?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  inProgress?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  new?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  skipped?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AWSDate?: GraphQLScalarType;
  AWSDateTime?: GraphQLScalarType;
  AWSEmail?: GraphQLScalarType;
  AWSIPAddress?: GraphQLScalarType;
  AWSJSON?: GraphQLScalarType;
  AWSPhone?: GraphQLScalarType;
  AWSTime?: GraphQLScalarType;
  AWSTimestamp?: GraphQLScalarType;
  AWSURL?: GraphQLScalarType;
  Batch?: BatchResolvers<ContextType>;
  BatchCounter?: BatchCounterResolvers<ContextType>;
  BatchStation?: BatchStationResolvers<ContextType>;
  BatchStations?: BatchStationsResolvers<ContextType>;
  Batches?: BatchesResolvers<ContextType>;
  BigInt?: GraphQLScalarType;
  CognitoGroup?: CognitoGroupResolvers<ContextType>;
  Collo?: ColloResolvers<ContextType>;
  ColloInfo?: ColloInfoResolvers<ContextType>;
  Config?: ConfigResolvers<ContextType>;
  ConfigInfo?: ConfigInfoResolvers<ContextType>;
  ConfigSequence?: ConfigSequenceResolvers<ContextType>;
  Double?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  PaginatedCognitoGroups?: PaginatedCognitoGroupsResolvers<ContextType>;
  Part?: PartResolvers<ContextType>;
  PrintCode?: PrintCodeResolvers<ContextType>;
  Printer?: PrinterResolvers<ContextType>;
  ProductionQueue?: ProductionQueueResolvers<ContextType>;
  ProductionQueues?: ProductionQueuesResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  QueuedItem?: QueuedItemResolvers<ContextType>;
  QueuedItems?: QueuedItemsResolvers<ContextType>;
  Schema?: SchemaResolvers<ContextType>;
  Station?: StationResolvers<ContextType>;
  StationCounter?: StationCounterResolvers<ContextType>;
  StationCounters?: StationCountersResolvers<ContextType>;
  Stations?: StationsResolvers<ContextType>;
  StopperPosition?: StopperPositionResolvers<ContextType>;
  StopperPositionError?: StopperPositionErrorResolvers<ContextType>;
  SubStation?: SubStationResolvers<ContextType>;
  SubStationConfig?: SubStationConfigResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Task?: TaskResolvers<ContextType>;
  Tasks?: TasksResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  Users?: UsersResolvers<ContextType>;
  batchCounterStates?: BatchCounterStatesResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  aws_api_key?: Aws_Api_KeyDirectiveResolver<any, any, ContextType>;
  aws_auth?: Aws_AuthDirectiveResolver<any, any, ContextType>;
  aws_cognito_user_pools?: Aws_Cognito_User_PoolsDirectiveResolver<any, any, ContextType>;
  aws_iam?: Aws_IamDirectiveResolver<any, any, ContextType>;
  aws_oidc?: Aws_OidcDirectiveResolver<any, any, ContextType>;
  aws_subscribe?: Aws_SubscribeDirectiveResolver<any, any, ContextType>;
};

export type ChangeUserPasswordMutationVariables = Exact<{
  tempPassword: Scalars['String'];
  username: Scalars['String'];
}>;


export type ChangeUserPasswordMutation = { __typename?: 'Mutation', changeUserPassword?: { __typename?: 'User', createdAt: string, email?: string | null | undefined, enabled: boolean, groups: Array<UserGroup | null | undefined>, location: string, userId: string, username: string } | null | undefined };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', username: string, userId: string, location: string, groups: Array<UserGroup | null | undefined>, enabled: boolean, email?: string | null | undefined, createdAt: string } | null | undefined };

export type DisableUserMutationVariables = Exact<{
  username: Scalars['String'];
}>;


export type DisableUserMutation = { __typename?: 'Mutation', disableUser?: { __typename?: 'User', createdAt: string, email?: string | null | undefined, enabled: boolean, groups: Array<UserGroup | null | undefined>, location: string, userId: string, username: string } | null | undefined };

export type DeleteUserMutationVariables = Exact<{
  username: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: { __typename?: 'User', createdAt: string, email?: string | null | undefined, enabled: boolean, groups: Array<UserGroup | null | undefined>, location: string, userId: string, username: string } | null | undefined };

export type EnableUserMutationVariables = Exact<{
  username: Scalars['String'];
}>;


export type EnableUserMutation = { __typename?: 'Mutation', enableUser?: { __typename?: 'User', createdAt: string, email?: string | null | undefined, enabled: boolean, groups: Array<UserGroup | null | undefined>, location: string, userId: string, username: string } | null | undefined };

export type ResetTaskMutationVariables = Exact<{
  stationId: Scalars['Int'];
  subStationId: Scalars['ID'];
  taskId: Scalars['ID'];
}>;


export type ResetTaskMutation = { __typename?: 'Mutation', resetTask?: { __typename?: 'Task', taskId: string } | null | undefined };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', createdAt: string, email?: string | null | undefined, enabled: boolean, groups: Array<UserGroup | null | undefined>, location: string, userId: string, username: string } | null | undefined };

export type GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsQuery = { __typename?: 'Query', groups: { __typename?: 'PaginatedCognitoGroups', items: Array<{ __typename?: 'CognitoGroup', createdAt?: any | null | undefined, groupName: string, precedence: number, updatedAt?: any | null | undefined }> } };

export type GetTasksByBatchIdQueryVariables = Exact<{
  batchId: Scalars['String'];
  filter?: Maybe<TaskFilterInput>;
}>;


export type GetTasksByBatchIdQuery = { __typename?: 'Query', getTasksByBatchId: { __typename?: 'Tasks', items: Array<{ __typename?: 'Task', batchId: string, colloId: number, paint: boolean, createdAt: any, queued?: number | null | undefined, queuedAt?: any | null | undefined, state: TaskState, stationId: number, subStationId?: string | null | undefined, taskId: string, updatedAt: any, station?: { __typename?: 'Station', department?: string | null | undefined, name?: string | null | undefined } | null | undefined, user?: { __typename?: 'User', userId: string, username: string } | null | undefined }> } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: { __typename: 'Users', nextToken?: string | null | undefined, items?: Array<{ __typename: 'User', userId: string, username: string, location: string, groups: Array<UserGroup | null | undefined>, enabled: boolean, email?: string | null | undefined, createdAt: string } | null | undefined> | null | undefined } };

export type OnUserChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUserChangedSubscription = { __typename?: 'Subscription', onUserChanged?: { __typename?: 'User', createdAt: string, email?: string | null | undefined, enabled: boolean, groups: Array<UserGroup | null | undefined>, location: string, userId: string, username: string } | null | undefined };


export const GqlChangeUserPassword = gql`
    mutation ChangeUserPassword($tempPassword: String!, $username: String!) {
  changeUserPassword(tempPassword: $tempPassword, username: $username) {
    createdAt
    email
    enabled
    groups
    location
    userId
    username
  }
}
    `;
export const GqlCreateUser = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    username
    userId
    location
    groups
    enabled
    email
    createdAt
  }
}
    `;
export const GqlDisableUser = gql`
    mutation DisableUser($username: String!) {
  disableUser(username: $username) {
    createdAt
    email
    enabled
    groups
    location
    userId
    username
  }
}
    `;
export const GqlDeleteUser = gql`
    mutation DeleteUser($username: String!) {
  deleteUser(username: $username) {
    createdAt
    email
    enabled
    groups
    location
    userId
    username
  }
}
    `;
export const GqlEnableUser = gql`
    mutation EnableUser($username: String!) {
  enableUser(username: $username) {
    createdAt
    email
    enabled
    groups
    location
    userId
    username
  }
}
    `;
export const GqlResetTask = gql`
    mutation ResetTask($stationId: Int!, $subStationId: ID!, $taskId: ID!) {
  resetTask(stationId: $stationId, subStationId: $subStationId, taskId: $taskId) {
    taskId
  }
}
    `;
export const GqlUpdateUser = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    createdAt
    email
    enabled
    groups
    location
    userId
    username
  }
}
    `;
export const GqlGroups = gql`
    query Groups {
  groups {
    items {
      createdAt
      groupName
      precedence
      updatedAt
    }
  }
}
    `;
export const GqlGetTasksByBatchId = gql`
    query GetTasksByBatchId($batchId: String!, $filter: TaskFilterInput) {
  getTasksByBatchId(batchId: $batchId, filter: $filter) {
    items {
      batchId
      colloId
      paint
      createdAt
      queued
      queuedAt
      state
      stationId
      subStationId
      taskId
      updatedAt
      station {
        department
        name
      }
      user {
        userId
        username
      }
    }
  }
}
    `;
export const GqlUsers = gql`
    query Users {
  users(location: "Zeewolde") {
    items {
      userId
      username
      location
      groups
      enabled
      email
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
    `;
export const GqlOnUserChanged = gql`
    subscription OnUserChanged {
  onUserChanged {
    createdAt
    email
    enabled
    groups
    location
    userId
    username
  }
}
    `;